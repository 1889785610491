// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Documents_section_3pvi6{display:grid;grid-template-rows:1fr min-content;align-items:center;justify-content:center;width:100%;max-width:768px;height:100%;min-height:500px;margin:0 auto 0;padding:21px 0 0;overflow:hidden;background-color:#fff;row-gap:21px}.Documents_document_3pEZY{position:absolute;top:0;left:0;height:100%;padding:16px;overflow:auto}.Documents_document_3pEZY :first-child{margin-top:0}.Documents_document_3pEZY :last-child{margin-bottom:0}.Documents_document_3pEZY h1{font-size:22px;line-height:31px}.Documents_document_3pEZY h1,.Documents_document_3pEZY h2{margin:16px 0 16px;color:#333}.Documents_document_3pEZY h2{font-size:20px;line-height:29px}.Documents_document_3pEZY h3{font-size:16px;line-height:25px}.Documents_document_3pEZY h3,.Documents_document_3pEZY h4{margin:16px 0 16px;color:#333}.Documents_document_3pEZY h4{font-size:15px;line-height:21px}.Documents_document_3pEZY h5{margin:16px 0 16px;line-height:19px}.Documents_document_3pEZY h5,.Documents_document_3pEZY p{color:#333;font-size:14px}.Documents_document_3pEZY p{margin:16px 0;font-weight:400;line-height:21px}.Documents_document_3pEZY p span{font-weight:inherit;font-family:inherit;line-height:inherit}.Documents_document_3pEZY ul{margin:16px 0;padding:0 24px}.Documents_document_3pEZY ul li{margin:0;padding:0;color:#333;font-size:14px;line-height:21px;list-style:disc}.Documents_document_3pEZY ol{margin:16px 0;padding:0 24px}.Documents_document_3pEZY ol li{margin:0;padding:0;color:#333;font-size:14px;line-height:21px;list-style:decimal}", ""]);
// Exports
exports.locals = {
	"section": "Documents_section_3pvi6",
	"document": "Documents_document_3pEZY"
};
module.exports = exports;
