/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { DataType } from './types'

export default {
  getDocument: async (slug: string): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`documents/${slug}`)
    return data
  },
}
