<template>
  <section :class="$style.section">
    <div v-if="doc">
      <div :class="[$style.document]" v-html="doc.content"></div>
    </div>
    <div v-else-if="!setPageLoading">{{ $t('documentNotFound') }}</div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import DocumentsApi from '@services/documents'

export default Vue.extend({
  name: 'Documents',
  data() {
    return {
      doc: null,
    }
  },

  methods: {
    ...mapMutations('Page', [
      'setPageIsHeader',
      'setPageIsFooter',
      'setPageLoading',
      'setPageTitle',
      'setPageBack',
    ]),
    async fetchDocument() {
      try {
        this.setPageLoading(true)
        const doc = await DocumentsApi.getDocument(this.$route.params.slug)
        this.doc = doc
      } catch (error) {
        this.doc = null
      } finally {
        this.setPageLoading(false)
      }
    },
  },
  watch: {
    '$route.fullPath': {
      async handler() {
        await this.fetchDocument()
        this.setPageTitle({ name: this.doc?.title ?? this.$t('documentsTitle') })
      },
      immediate: true,
    },
  },
  async mounted() {
    this.setPageBack({
      isDisabled: false,
    })
    this.setPageIsFooter(false)
    await this.fetchDocument()
    this.setPageTitle({ name: this.doc?.title ?? this.$t('documentsTitle') })
  },
  destroyed() {
    this.setPageIsFooter(true)
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: 1fr min-content;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: $width;
  height: 100%;
  min-height: 500px;
  margin: 0 auto 0;
  padding: 21px 0 0;
  overflow: hidden;
  background-color: #fff;
  row-gap: 21px;
}

.document {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 16px;
  overflow: auto;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  h1 {
    margin: 16px 0 16px;
    color: #333;
    font-size: 22px;
    line-height: 31px;
  }
  h2 {
    margin: 16px 0 16px;
    color: #333;
    font-size: 20px;
    line-height: 29px;
  }
  h3 {
    margin: 16px 0 16px;
    color: #333;
    font-size: 16px;
    line-height: 25px;
  }
  h4 {
    margin: 16px 0 16px;
    color: #333;
    font-size: 15px;
    line-height: 21px;
  }
  h5 {
    margin: 16px 0 16px;
    color: #333;
    font-size: 14px;
    line-height: 19px;
  }
  p {
    margin: 16px 0;
    color: #333;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    span {
      font-weight: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  }
  ul {
    margin: 16px 0;
    padding: 0 24px;
    li {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 14px;
      line-height: 21px;
      list-style: disc;
    }
  }
  ol {
    margin: 16px 0;
    padding: 0 24px;
    li {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 14px;
      line-height: 21px;
      list-style: decimal;
    }
  }
}
</style>
